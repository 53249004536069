@keyframes incommingPackage {
  0% {
    transform: rotate(0deg) translate(0, 0);
  }

  50% {
    transform: rotate(10deg) translate(25px, -11px);
  }

  100% {
    transform: rotate(16deg) translate(40px, -19px);
  }
}

.iconPackage {
  position: absolute;
  top: 105px;
  left: 40px;
  color: #fff;
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  animation: incommingPackage 1s linear infinite;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.iconPostOffice {
  position: absolute;
  top: 101px;
  right: 35px;
  color: #fff;
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
}
