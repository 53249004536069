@keyframes bouncingAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0);
  }
}

.bouncingTruck {
  position: absolute;
  width: 46px;
  height: 46px;
  background-position: center;
  background-repeat: no-repeat;
  animation: bouncingAnimation 0.7s linear infinite;
  animation-delay: 1s;
}
