$firstRadius: 647.168;
$secondRadius: 517.301;
$thirdRadius: 317.301;
$fourthRadius: 117.301;
$fifthRadius: 1;

@mixin keyframesMixin($animationName, $startPoint, $endPoint) {
  @keyframes #{$animationName} {
    from {
      stroke-dashoffset: $startPoint;
    }

    to {
      stroke-dashoffset: $endPoint;
    }
  }
}

@include keyframesMixin(step-1, $firstRadius, $firstRadius);
@include keyframesMixin(step-2, $firstRadius, $secondRadius);
@include keyframesMixin(step-3, $secondRadius, $thirdRadius);
@include keyframesMixin(step-4, $thirdRadius, $fourthRadius);
@include keyframesMixin(step-5, $fourthRadius, $fifthRadius);

.ground, .deliveryStatusTitle, .deliveryDate {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.deliveryStatusTitle {
  top: 50px;
  color: #000;
  max-width: 120px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
}

.deliveryDate {
  top: 150px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.ground {
  width: 170px;
  height: 77px;
  border-radius: 50%;
  top: 130px;
}

.circleWrapper {
  width: 220px;
  height: 220px;
  position: relative;
  display: flex;
  flex: 0 0 220px;
  border-radius: 50%;
  overflow: hidden;
}

.circleWrapper .progress {
  transform: rotate(-90deg);
}

.progress__value {
  stroke-dasharray: $firstRadius;
  stroke-dashoffset: $firstRadius;
  stroke-linecap: round;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
