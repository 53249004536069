@keyframes incommingAnimation {
  0% {
    transform: rotate(0deg) translate(0, 0);
  }

  50% {
    transform: rotate(10deg) translate(25px, -11px);
  }

  100% {
    transform: rotate(16deg) translate(50px, -22px);
  }
}

.incommingTruckWrapper {
  position: absolute;
  top: 105px;
  left: 35px;
  width: 46px;
  height: 46px;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
