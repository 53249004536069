.iconHome {
  position: absolute;
  top: 97px;
  right: 33px;
  color: #fff;
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
}
