.icon {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 81px;
  color: #fff;
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
}
