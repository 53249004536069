@keyframes returnAnimation {
  0% {
    transform: rotate(22deg) translate(0, 0);
  }

  50% {
    transform: rotate(10deg) translate(-25px, 6px);
  }

  100% {
    transform: rotate(2deg) translate(-50px, 9px);
  }
}

.returnTruckWrapper {
  position: absolute;
  top: 97px;
  left: 85px;
  transform: rotate(22deg);
  width: 46px;
  height: 46px;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
